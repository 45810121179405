<template>
    <div class="animated fadeIn">
      <b-row>
        <b-col sm="12">
          <!-- {{ $route.query }} -->
          <c-table
            :url="'CustomerWebService/get_customers_npl'"
            :per-page="10"
            :fields="columns"
            :columns="[
              'firstname',
              'lastname',
              'register',
              'phone'
            ]"
            :filename="'Хэрэглэгч'"
            :filters="filters"
            orderDirection="DESC"
            orderBy="created_date"
            hover
            bordered
            fixed
            caption=""
          >
          </c-table>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  export default {
    name: "Customers.list",
    data: function() {
      return {
        columns: [
          { label: "№", key: "index", class: "text-center" },
          {
            label: "Овог",
            key: "lastname",
            sortable: true,
            sort_key: "lastname",
          },
          {
            label: "Нэр",
            key: "firstname",
            sortable: true,
            sort_key: "firstname",
          },
          {
            label: "Регистр",
            key: "register",
            sortable: true,
            sort_key: "register",
          },
          { label: "Утас", key: "phone", sortable: true, sort_key: "phone" },
          {
            label: "Хугацаа хэтэрсэн хоног",
            key: "overdueDay",
            sortable: true,
            sort_key: "loan_max_amount",
            class: "text-right",
          }
        ],
      };
    },
    created: function() {
      var _buttons = [];
      
    _buttons.push({
        icon: "fa fa-id-card",
        label: "",
        variant: "outline-primary",
        title: "Хэрэглэгчийн дэлгэрэнгүй",
        route: "admin.customer.detail",
        route_param: "id",
    });
    
      if (_buttons.length > 0) {
        this.$data.columns.push({
          label: "Үйлдэл",
          key: "actions",
          buttons: _buttons,
          class: "text-center",
        });
      }
    },
  };
  </script>
  